@tailwind base;
@import 'node_modules/@aca/ui/tailwind.base.scss';
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'material';

html {
  @apply w-full h-full m-0 p-0;
}

body {
  @apply min-h-screen min-w-full m-0 p-0 flex flex-col;
  font-family: 'Source Sans Pro', 'Helvetica Neue', sans-serif;
}

body.aca-modal-open {
  @apply overflow-hidden;
}

body {
  overflow-y: scroll;
}

body {
  overflow-y: scroll;
}

.content-bg {
  @apply bg-scroll bg-cover fixed h-full w-full bg-[#f0f0f0] -z-[1];
  background-image: linear-gradient(to bottom, #f0f0f0 50%, #eaf4ff 100%);
}

.subtle {
  @apply text-sm text-dark-500 dark:text-dark-300;
}

.text-muted {
  @apply text-dark-500;
}

.dropdown-subheader {
  @apply px-4 py-1 text-dark-500 dark:text-dark-300 uppercase text-sm;
}

.limit-menu-height {
  @apply max-h-[330px] overflow-y-auto #{!important};
}

.icon-menu {
  &.mat-mdc-menu-panel.mat-mdc-menu-panel {
    min-width: 200px;
    button {
      .mdc-list-item__primary-text {
        @apply flex flex-grow items-center gap-2;
      }
    }
  }
  .mat-divider {
    @apply border-dark-200 dark:border-dark-500 my-1;
  }
}

main {
  @apply p-2 md:p-4 text-dark-800 min-w-full inline-block;
}

section {
  @apply p-3 pt-2 md:p-6 md:pt-5 bg-white relative rounded-md;
  border: solid 1px rgba(0, 0, 0, 0.1);

  &:not(.no-shadow) {
    @apply shadow-lg;
  }

  h1,
  h2 {
    @apply mt-0 mb-3;
  }

  .title {
    @apply flex;
    > div:first-child {
      @apply flex-grow;
    }
  }
}

.unified-platform-ui-gap {
  @apply gap-2 md:gap-4;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
