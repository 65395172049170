@use '@angular/material' as mat;
@import 'variables';
@include mat.core();

// https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md

$my-typography: mat.define-typography-config(
  $font-family: 'inherit',
);

$aca-base-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$indigo-palette),
      accent: mat.define-palette(mat.$green-palette),
    ),
    typography: $my-typography,
  )
);

.aca-theme-customer {
  @include mat.all-component-themes($aca-base-theme);

  &.aca-theme-light {
    mat-card-title {
      border-bottom: solid 1px #d0d0d0;
    }
  }

  &.aca-theme-dark {
    $aca-dark-theme: mat.define-dark-theme(
      (
        color: (
          primary: mat.define-palette(mat.$indigo-palette),
          accent: mat.define-palette(mat.$green-palette),
        ),
        typography: $my-typography,
      )
    );

    @include mat.all-component-colors($aca-dark-theme);

    mat-card-title {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }

  mat-card-title {
    padding: 10px 16px;
  }

  mat-card-content {
    font-size: inherit;
  }
}
